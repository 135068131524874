import React, { useEffect, useState } from 'react';
import TopCrumb from '../components/TopCrumb';
import questionApi from '../services/questionActions';
import { message } from 'antd';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import ReactPlayer from 'react-player/lazy';

const Video = () => {
  const [searchParams] = useSearchParams();
  const curModule = searchParams.get('module');
  const { module } = useSelector((state) => state.user);
  const [urlString, setUrlString] = useState('');

  useEffect(() => {
    if (module) {
      setUrlString(module.vimeo_link);
    }
  }, [module, curModule]);

  const onVideoEnd = () => {
    let data = {
      course_id: module?.id,
      type: 'video',
    };

    const courseStatusUpdateVideo = async () => {
      try {
        const videoData = await questionApi.courseStatusAction(data);

        message.success('Video Completed');
        if (videoData) {
          if (Number(curModule) > 0) {
            setTimeout(() => {
              window.location.href = `/assessment?module=${curModule}`;
            }, 2000);
          }
        }
      } catch (error) {
        // console.log('error', error);
        if (error.response?.data.error) {
          message.error(error.response?.data.error);
        }
      }
    };
    // console.log('submit submit');
    courseStatusUpdateVideo();
  };

  return (
    <div className="container">
      <div className="mt-4">
        <TopCrumb module={module} type="Video" />

        <div className="video-container mt-4 ">
          <div className="video-wrapper">
            <ReactPlayer
              controls={true}
              url={urlString}
              // url="https://drive.google.com/uc?export=download&id=137S2YTbJT1PSiTGIs6S_ZjUTpxnkUgqh"
              width="100%"
              height={'100%'}
              onEnded={onVideoEnd}
              config={{ file: { attributes: { controlsList: 'nodownload' } } }}
              onContextMenu={(e) => e.preventDefault()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Video;
